@font-face {
  font-family: "hk-grotesk--regular";
  src: url("../fonts/hk-grotesk--regular/hk-grotesk--regular.eot");
  src: url("../fonts/hk-grotesk--regular/hk-grotesk--regular.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk--regular/hk-grotesk--regular.woff2") format("woff2"), url("../fonts/hk-grotesk--regular/hk-grotesk--regular.woff") format("woff"), url("../fonts/hk-grotesk--regular/hk-grotesk--regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "hk-grotesk--italic";
  src: url("../fonts/hk-grotesk--italic/hk-grotesk--italic.eot");
  src: url("../fonts/hk-grotesk--italic/hk-grotesk--italic.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk--italic/hk-grotesk--italic.woff2") format("woff2"), url("../fonts/hk-grotesk--italic/hk-grotesk--italic.woff") format("woff"), url("../fonts/hk-grotesk--italic/hk-grotesk--italic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "hk-grotesk--semibold";
  src: url("../fonts/hk-grotesk--semibold/hk-grotesk--semibold.eot");
  src: url("../fonts/hk-grotesk--semibold/hk-grotesk--semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk--semibold/hk-grotesk--semibold.woff2") format("woff2"), url("../fonts/hk-grotesk--semibold/hk-grotesk--semibold.woff") format("woff"), url("../fonts/hk-grotesk--semibold/hk-grotesk--semibold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --ratio: 1.2;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.24;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.28;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  z-index: 100;
  outline-offset: 4px;
  outline: 2px dashed #fa7d00;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  will-change: unset;
  transform: none;
  filter: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}
.main_content {
  z-index: 10;
  flex: 1;
  width: 100%;
}
.page_content {
  flex: 1;
  position: relative;
  z-index: 20;
  width: 100%;
  background-color: #eee3db;
}
.page_content__inner {
  display: flex;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: center;
}
.page_content__inner > *:first-child {
  margin-top: 4.854rem;
}
.page_content__inner > *:last-child {
  margin-bottom: 4.854rem;
}
.reading_content {
  max-width: 100%;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 14px;
}
@media screen and (min-width: 440px) {
  :root {
    font-size: calc(0.6349206349206349vw + 11.206349206349206px);
  }
}
@media screen and (min-width: 1700px) {
  :root {
    font-size: 22px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: "hk-grotesk--regular", sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: -0.03em;
  color: #135079;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: "Spectral", serif;
  font-weight: normal;
  letter-spacing: -0.054em;
  color: #0c334d;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: #f5941c;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: #d57a09;
}
h1 {
  max-width: 30ch;
  font-size: 2.0736rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 2.36421376rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.68435456rem;
    font-size: var(--fz-ratio-power--four);
  }
}
* + h1 {
  margin-top: 3.1104rem;
  margin-top: var(--sp-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  * + h1 {
    margin-top: 3.54632064rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  * + h1 {
    margin-top: 4.026531840000001rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
h2 {
  max-width: 30ch;
  font-size: 1.728rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.906624rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 2.097152rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 2.592rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.859936rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 3.145728000000001rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 42ch;
  font-size: 1.44rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 2.16rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 2.3064rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.4576rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 50ch;
  font-size: 1.2rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.28rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.8rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.86rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.92rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: "hk-grotesk--regular", sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.03em;
  color: #135079;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content {
  width: 60ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  will-change: box-shadow, color;
  transition-property: box-shadow, color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  box-shadow: inset 0 0 0 hsla(33.2,92%,44%,0.4);
  color: #f5941c;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover {
  box-shadow: inset 0 -2px 0 hsla(33.2,92%,44%,0.4);
  color: #d57a09;
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
}
strong,
b {
  font-weight: normal;
  font-family: "hk-grotesk--semibold", sans-serif;
}
em,
i {
  font-style: normal;
  font-family: "hk-grotesk--italic", sans-serif;
}
::selection {
  background-color: #386e71;
  text-shadow: 0 0 0.1em #0d5259;
  color: #fff;
}
* + .reading_content {
  margin-top: 1.618rem;
}
.reading_content ul,
.reading_content ol,
.reading_content dl {
  display: block;
  width: 100%;
  max-width: 60ch;
  padding-left: 3ch;
}
.reading_content * + ul,
.reading_content * + ol,
.reading_content * + dl {
  margin-top: 1.618rem;
}
.reading_content ul {
  list-style-type: circle;
}
.reading_content ul ul {
  list-style-type: disc;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content li {
  max-width: 100%;
}
.reading_content li ul:first-child,
.reading_content li ol:first-child {
  margin-top: 0.809rem;
}
.reading_content * + li {
  margin-top: 0.539333333333333rem;
}
sup {
  vertical-align: super;
  font-size: 0.833333333333333rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
}
.global_header__global_content {
  position: absolute;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 1081px) {
  .global_header__global_content {
    justify-content: flex-start;
  }
}
.global_header__navigation {
  height: 100%;
  padding: 1.077588rem;
}
@media only screen and (max-width: 1080px) {
  .global_header__navigation {
    margin-left: auto;
  }
}
.global_header__navigation__list {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.global_header__navigation__item {
  flex: 0 0 auto;
}
* + .global_header__navigation__item {
  margin-left: 0.539333333333333rem;
}
.global_header__navigation__anchor {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border-bottom: 1px solid #f5941c;
  background-color: #fff;
  padding: 0.539333333333333rem 1.077588rem;
}
.global_header__navigation__anchor__text,
.global_header__navigation__anchor svg {
  flex: 0 0 auto;
}
.global_header__navigation__anchor__text {
  letter-spacing: -0.01em;
  font-size: 0.833333333333333rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #135079;
}
@media only screen and (min-width: 681px) {
  .global_header__navigation__anchor__text {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_header__navigation__anchor__text {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header__navigation__anchor svg {
  margin-left: 0.269666666666667rem;
  height: 0.4045rem;
  width: 0.609rem;
  fill: #f5941c;
}
.global_header__logo_anchor {
  position: absolute;
  z-index: auto;
  top: 1.077588rem;
  left: 1.077588rem;
  display: block;
  margin-left: -1.618rem;
}
@media only screen and (min-width: 1281px) {
  .global_header__logo_anchor {
    position: fixed;
    z-index: auto;
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__logo_anchor {
    left: 50%;
  }
}
@media only screen and (max-width: 1080px) {
  .global_header__logo_anchor {
    margin-left: 0;
  }
}
.global_header__logo_vector,
.global_header__logo_anchor {
  height: 3.236rem;
  width: 3.236rem;
}
.global_header__local_content {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  max-height: 60rem;
  width: 100%;
  background-color: #f5f3f2;
}
@media only screen and (min-width: 1081px) {
  .global_header__local_content {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__local_content {
    height: calc(100vh - 1.077588rem);
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__local_content {
    padding: 1.077588rem;
  }
}
@media only screen and (max-width: 1080px) {
  .global_header__local_content {
    padding-bottom: 1px;
  }
}
.global_header__local_content__decorative_triangle {
  position: absolute;
  z-index: 10;
  bottom: -1px;
  left: 0;
  height: 3.236rem;
  width: 100%;
  fill: #eee3db;
}
@media only screen and (min-width: 1081px) {
  .global_header__local_content__decorative_triangle {
    width: calc(60% - 1.077588rem);
  }
}
.global_header__summary {
  padding: 9.708rem 1.077588rem 3.236rem;
}
@media only screen and (min-width: 1081px) {
  .global_header__summary {
    flex: 0 0 60%;
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__summary {
    padding: 0;
  }
}
.global_header__summary__text {
  max-width: 32ch;
  color: #a27f8b;
}
.global_header__image {
  border-right: 2px solid #a27f8b;
  height: 8rem;
  width: 100%;
  background-image: url("../images/index_local_header_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1081px) {
  .global_header__image {
    flex: 0 0 40%;
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__image {
    height: 100%;
  }
}
@media only screen and (min-width: 1081px) {
  .global_header__image {
    background-position: center;
  }
}
.heading_with_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 100%;
  max-width: 1240px;
  padding: 3.236rem 0;
}
@media only screen and (min-width: 881px) {
  .heading_with_details {
    flex-direction: row;
  }
}
* + .heading_with_details {
  margin-top: 3.236rem;
}
.heading_with_details + .heading_with_details {
  margin-top: 0;
  border-top: 0;
}
@media only screen and (min-width: 881px) {
  .heading_with_details__heading {
    flex: 1 1 0;
  }
}
@media only screen and (max-width: 880px) {
  .heading_with_details__heading {
    max-width: 100%;
  }
}
.heading_with_details__heading__text {
  display: inline-block;
  line-height: 1.1;
}
.heading_with_details__action_anchor_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
* + .heading_with_details__action_anchor_container {
  margin-top: 1.2135rem;
}
.heading_with_details__action_anchor {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border-bottom: 1px solid #f5941c;
  background-color: #f5f3f2;
  padding: 0.20225rem 0.539333333333333rem;
}
* + .heading_with_details__action_anchor {
  margin-left: 0.809rem;
}
.heading_with_details__action_anchor__text,
.heading_with_details__action_anchor svg {
  flex: 0 0 auto;
}
.heading_with_details__action_anchor__text {
  letter-spacing: -0.01em;
  font-size: 0.833333333333333rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #135079;
}
@media only screen and (min-width: 681px) {
  .heading_with_details__action_anchor__text {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .heading_with_details__action_anchor__text {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.heading_with_details__action_anchor svg {
  margin-left: 0.269666666666667rem;
  height: 0.4045rem;
  width: 0.609rem;
  fill: #f5941c;
}
.heading_with_details__details {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  .heading_with_details__details {
    flex: 0 0 60ch;
  }
}
@media only screen and (min-width: 881px) {
  .heading_with_details__details {
    margin-top: 3.036rem;
    margin-left: 1.618rem;
  }
}
@media only screen and (max-width: 880px) {
  .heading_with_details__details {
    max-width: 100%;
  }
}
.quotation_block {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 46rem;
  padding: 6.472rem 0;
}
.quotation_block__background_first,
.quotation_block__background_second {
  position: absolute;
  z-index: -1;
  height: auto;
}
.quotation_block__background_first {
  top: 5.2rem;
  left: -1.5rem;
  width: 8rem;
  opacity: 0.08;
}
.quotation_block__background_second {
  right: -2.3rem;
  bottom: 4.3rem;
  width: 16rem;
  opacity: 0.6;
}
.quotation_block__quotation {
  margin-right: auto;
  width: 60ch;
  max-width: 100%;
}
@media only screen and (min-width: 881px) {
  .quotation_block__quotation {
    width: 70%;
  }
}
.quotation_block__quotation__text {
  display: inline;
  box-shadow: inset 0 -0.12em 0 #fff;
  line-height: 1.5;
  font-family: "hk-grotesk--semibold", sans-serif;
  font-size: 1.2rem;
  font-size: var(--fz-ratio-power--one);
  color: #a27f8b;
}
@media only screen and (min-width: 681px) {
  .quotation_block__quotation__text {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .quotation_block__quotation__text {
    font-size: 1.28rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.quotation_block__citation {
  margin-top: 1.618rem;
  margin-left: auto;
  text-align: center;
}
@media only screen and (min-width: 881px) {
  .quotation_block__citation {
    margin-top: 0;
  }
}
@media only screen and (min-width: 881px) {
  .quotation_block__citation {
    width: 30%;
  }
}
@media only screen and (min-width: 881px) {
  .quotation_block__citation {
    text-align: right;
  }
}
.quotation_block__citation__text {
  text-transform: uppercase;
  font-size: 0.833333333333333rem;
  font-size: var(--fz-ratio-power--minus-one);
  letter-spacing: 0;
}
@media only screen and (min-width: 681px) {
  .quotation_block__citation__text {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .quotation_block__citation__text {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.quotation_block__citation__text::before {
  content: "—";
  display: inline-block;
  margin-right: 1ch;
  color: #fff;
}
.section_heading {
  position: relative;
  z-index: auto;
  display: inline-block;
  margin-bottom: 3.236rem;
  border-bottom: 2px solid #fff;
  background: linear-gradient(to right, #fff 2px, transparent 2px, transparent), linear-gradient(to left, #fff 2px, transparent 2px, transparent);
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 100% 40%;
  padding: 0 2.156794rem 0.4045rem 2.156794rem;
}
* + .section_heading {
  margin-top: 4.854rem;
}
.section_heading::before {
  content: "";
  transform: translateX(-50%);
  position: absolute;
  z-index: auto;
  bottom: -3.236rem;
  left: 50%;
  height: 3.236rem;
  width: 2px;
  background-color: #fff;
}
